import React from 'react';
import PropTypes from 'prop-types'
import Logo from './Logo'

const LogoContainer = ({ logos }) => {
  const logoRemainderValue = logos.length % 4;
  const mainLogosBlock = logos;
  const remainingLogosRow = mainLogosBlock.splice(-logoRemainderValue);

  const columnWidthValue = {
    0: "3",
    3: "4",
    2: "6",
    1: "12"
  };

  return (
    <div className="row">

      {mainLogosBlock.map(logo => (
        <div
          key={logo.title}
          className="col-4-sm col-3 center"
        >
          <Logo
            image={logo.companyLogo.file.url}
            alt={logo.title}
          />
        </div>
      ))}

      {remainingLogosRow.map(logo => (
        <div
          key={logo.title}
          className={`col-4-sm col-${ columnWidthValue[logoRemainderValue] || 3 } center`}
        >
          <Logo
            image={logo.companyLogo.file.url}
            alt={logo.title}
          />
        </div>
      ))}

    </div>
  )
};

LogoContainer.propTypes = {
  logos: PropTypes.array.isRequired
};

export default LogoContainer;
