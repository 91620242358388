import React from 'react';
import { graphql } from 'gatsby';

import Badge from '../components/shared/Badge';
import Button from '../components/shared/Button';
import Header from '../components/Header';
import Layout from '../components/Layout';
import LogoContainer from '../components/vara-tjanster/LogoContainer';

import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { richTextParse } from '../utils/utility-functions';

import delimiter2 from '../assets/graphics/flatwave-delimiter-2.png';
import delimiter3 from '../assets/graphics/flatwave-delimiter-3.png';

const Top = ({ topContent, topButton, services }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 center-sm services">
          { richTextParse(topContent[0]) }
        </div>
      </div>
      <div className="row">
        {services.map((service) => (
            <Badge
              key={ service.title }
              title={ service.title }
              icon={ service.icon.file.url }
              quote={ richTextParse(service.quote) }
            />
          ))}
      </div>
      <div className="row">
        <div className="col-12 center">
          <Button
            linkType={ topButton.linkType }
            link={ topButton.link }
            text={ topButton.text }
            color={ topButton.color }
          />
        </div>
      </div>
    </div>
  )
};

const Middle = ({ logos }) => (
  <div className="middle">
    <div className="waves waves--mirrored"></div>
    <img className="delimiter-top" src={ delimiter2 } alt={ "Flatwave delimiter" } />
    <div className="container">
      <div className="row">
        <div className="col-12 center-sm">
          <h2 className="client-heading white">Who we work with</h2>
        </div>
      </div>
      <LogoContainer logos={ logos } />
    </div>
    <img className="delimiter-bottom" src={ delimiter3 } alt={ "Flatwave delimiter" } />
  </div>
);

const Bottom = ({ bottomContent }) => {
  return (
    <div id="kontakt" className="container">
      <div className="row">
        <div className="col-4 hidden-sm"></div>
        <div className="col-12 center-sm">
          { richTextParse(bottomContent[0]) }
        </div>
      </div>
    </div>
  )
};

const ServicesPage = ({ data }) => (
  <Layout>
    <div>
      <Helmet title={ `${data.contentfulOurServicesContent.title} | flatwave.se` } />
      <Header title={ data.contentfulOurServicesContent.title } />
      <Top
        topContent={ data.allContentfulOurServicesContentSectionTopRichTextNode.nodes }
        topButton={ data.contentfulOurServicesContentButtonTopSectionJsonNode }
        services={ data.allContentfulService.nodes }
      />
      <Middle logos={ data.allContentfulCompanies.nodes } />
      <Bottom bottomContent={ data.allContentfulOurServicesContentSectionBottomRichTextNode.nodes } />
    </div>
  </Layout>
);

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ServicesPage;

export const ourServicesPageQuery = graphql`
query OurServicesPageQuery {
  contentfulOurServicesContent {
    title
  }
  allContentfulOurServicesContentSectionTopRichTextNode {
    nodes {
      nodeType
      content {
        nodeType
        content {
          value
          nodeType
          marks {
            type
          }
        }
      }
    }
  }
  contentfulOurServicesContentButtonTopSectionJsonNode {
    text
    linkType
    link
    color
  }
  allContentfulOurServicesContentSectionBottomRichTextNode {
    nodes {
      nodeType
      content {
        nodeType
        content {
          value
          nodeType
          marks {
            type
          }
        }
      }
    }
  }
  allContentfulService(sort: {fields: id, order: ASC}) {
    nodes {
      title
      quote {
        nodeType
        content {
          nodeType
          content {
            value
            nodeType
            marks {
              type
            }
          }
        }
      }
      icon {
        file {
          url
        }
      }
    }
  }
  allContentfulCompanies(sort: {fields: ordering, order: ASC}) {
    nodes {
      title
      companyLogo {
        file {
          url
        }
      }
    }
  }
}

`;
