import React from 'react';
import PropTypes from 'prop-types'

const Logo = ({ image, alt }) => {
  return (
    <img
      src={image}
      alt={alt}
      className="client-logo"
    />
  )
};

Logo.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default Logo;
